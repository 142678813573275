<template>
  <div>
        <div class="card-body">
          <form @submit.prevent="update" class="form-costs-edit">
            <div class="form-group">
              <label for="title">{{ $t('tenants.title') }}</label>
              <input type="text" id="title" v-model="payload.title" class="form-control" :class="{'is-invalid': $v.payload.title.$invalid}">
              <small class="form-text" :class="{'text-danger': $v.payload.title.$invalid, 'text-muted': !$v.payload.title.$invalid}">{{payload.title ? payload.title.length : 0 }}/255<span v-if="!$v.payload.title.required"> {{ $t('tenants.required_field') }}</span></small>
            </div>
            <div class="form-group">
              <label for="users_limit">{{ $t('tenants.users_limit') }}</label>
              <input type="number" min="1" max="99" id="users_limit" v-model.number="payload.users_limit" :class="{'is-invalid': $v.payload.users_limit.$invalid}" class="form-control">
              <small class="form-text" v-if="$v.payload.users_limit.$invalid"
                     :class="{'text-danger': $v.payload.users_limit.$invalid, 'text-muted': !$v.payload.users_limit.$invalid}">{{ $t('tenants.user_limit_error') }}</small>
            </div>
            <div class="form-group">
              <label for="note">{{ $t('tenants.note') }}</label>
              <textarea rows="3" id="note" v-model="payload.note" :class="{'is-invalid': $v.payload.note.$invalid}" class="form-control"></textarea>
              <small class="form-text" :class="{'text-danger': $v.payload.note.$invalid, 'text-muted': !$v.payload.note.$invalid}">{{payload.note ? payload.note.length : 0 }}/255</small>
            </div>

            <div>
              <b-tabs content-class="mt-3" fill>
                <b-tab :title="$t('tenants.search_result_costs')" active>
                  <ServiceTable ref="serviceTable" @services="setSearchRates" @invalid="setInsideInvalid" @invalidServices="setServicesState"></ServiceTable>
                  <table class="table table-costs-edit tableFixHead">
                    <tfoot>
                    <tr class="table-secondary">
                      <td>{{ $t('tenants.database') }} <i class="feather icon-info"
                                                          v-tippy="{ placement : 'top',  arrow: true }"
                                                          :content="$t('tenants.info_default_db')"></i>
                      </td>
                      <td colspan="2">
                        <input type="number" min="0"
                               :class="{'is-invalid': $v.payload.search_rate.offline_result_price.$invalid}"
                               v-model.number="payload.search_rate.offline_result_price" class="input-inside m-r-10">
                        <span class="text-danger" v-if="$v.payload.search_rate.offline_result_price.$invalid">{{ $t('tenants.required_field') }}</span>
                      </td>
                    </tr>
                    <tr class="table-secondary">
                      <td>{{ $t('tenants.default_service') }} <i class="feather icon-info"
                                                                 v-tippy="{ placement : 'top',  arrow: true }"
                                                                 :content="$t('tenants.info_default_online')"></i></td>
                      <td>
                        <input type="number" min="0"
                               :class="{'is-invalid': $v.payload.search_rate.online_result_price.$invalid}"
                               v-model.number="payload.search_rate.online_result_price" class="input-inside m-r-10">
                        <span class="text-danger" v-if="$v.payload.search_rate.online_result_price.$invalid">{{ $t('tenants.required_field') }}</span>
                      </td>
                    </tr>
                    <tr class="table-secondary">
                      <td>{{ $t('tenants.max_total_cost') }}</td>
                      <td colspan="2">
                        <input type="number" min="50"
                               :class="{'is-invalid': $v.payload.search_rate.max_total_cost.$invalid}"
                               v-model.number="payload.search_rate.max_total_cost" class="input-inside m-r-10">
                        <span class="text-danger" v-if="!$v.payload.search_rate.max_total_cost.required">{{ $t('tenants.required_field') }} </span>
                        <span class="text-danger"
                              v-if="!$v.payload.search_rate.max_total_cost.minValue || !$v.payload.search_rate.max_total_cost.maxValue"
                        >{{ $t('tenants.max_limit_error') }}</span>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </b-tab>
                <b-tab :title="$t('tenants.monitoring_costs')">
                  <monitoring-service-table @services="setMonitoringRates"  @invalid="setInsideInvalid" @invalidMonitoring="setMonitoringState" ref="monitoringServiceTable"></monitoring-service-table>
                  <table class="table table-costs-edit tableFixHead">
                    <tfoot>
                    <tr class="table-secondary">
                      <td>{{ $t('tenants.info_online_status') }} <i class="feather icon-info"
                                                          v-tippy="{ placement : 'top',  arrow: true }"
                                                          :content="$t('tenants.info_online_status_info')"></i>
                      </td>
                      <td colspan="2">
                        <!--                               v-model.number="payload.search_rate.offline_result_price"-->

                        <input type="number" min="0"
                               v-model.number="online_status_price"
                               class="input-inside m-r-10">
                        <span class="text-danger" v-if="online_status_price === ''">{{ $t('tenants.required_field') }}</span>
                      </td>
                    </tr>
                    <tr class="table-secondary">
                      <td>{{ $t('tenants.location') }} <i class="feather icon-info"
                                                                 v-tippy="{ placement : 'top',  arrow: true }"
                                                                 :content="$t('tenants.location_info')"></i></td>
                      <td>
<!--                        v-model.number="payload.search_rate.online_result_price" class="input-inside m-r-10"-->
<!--                        :class="{'is-invalid': $v.payload.search_rate.online_result_price.$invalid}"-->


                        <input type="number" min="0"
                               v-model.number="location_price"
                               class="input-inside m-r-10">
                        <span class="text-danger" v-if="location_price === ''">{{ $t('tenants.required_field') }}</span>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </b-tab>
              </b-tabs>
            </div>
            <div class="form-group">
              <b-button variant="primary" class="btn" :class="{ 'btn-primary': !$v.$invalid, 'btn-primary': $v.$invalid || invalid }" type="submit"
                        :disabled="sending || $v.$invalid || invalid || online_status_price === '' || location_price ===''|| invalidMonitoring || invalidServices">{{ $t('user.save') }} <b-spinner small
                                                                                                       v-if="sending"></b-spinner></b-button>
            </div>
            <div v-if="invalidServices && invalidMonitoring" class="text-danger">Установите цену в таблице результатов поиска и таблице мониторинга</div>
            <div v-else-if="invalidServices && !invalidMonitoring" class="text-danger">Установите цену в таблице результатов поиска</div>
            <div v-else-if="!invalidServices && invalidMonitoring" class="text-danger">Установите цену в таблице мониторинга</div>
          </form>
        </div>
    </div>
</template>
<script>
import EditableSpan from './editable.vue';
import _ from 'underscore';
import { validationMixin } from 'vuelidate';
import { required, maxLength, minValue, maxValue } from "vuelidate/lib/validators";
import ServiceTable from './serviceTable.vue';
import MonitoringServiceTable from "@/components/tenants/MonitoringServiceTable";
import {mapGetters} from "vuex";

export default {
    name: 'TenantsForm',
    setup() {
        return {
            und: _
        }
    },
    mixins: [validationMixin],
    components: {MonitoringServiceTable, EditableSpan, ServiceTable },
    props: [],
    data() {
        return {
            payload: {
                title: null,
                note: null,
                services_search_rates: [],
                search_rate: {
                    offline_result_price: null,
                    online_result_price: null,
                    max_total_cost: null
                },
                monitoring_rates: null,
                users_limit: null
            },
            validationRules: {
                payload: {
                    title: {
                        required,
                        maxLength: maxLength(255)
                    },
                    note: {
                        maxLength: maxLength(255)
                    },
                    users_limit: {
                        maxValue: maxValue(99),
                        minValue: minValue(1)
                    },
                    search_rate: {
                        offline_result_price: { required },
                        online_result_price: { required },
                        max_total_cost: {
                            required,
                            minValue: minValue(50),
                            maxValue: maxValue(1000)
                        }
                    }
                }
            },
            sending: false,
            invalid: false,
            temp: [],
            online_status_price: 0,
            location_price: 0,
            invalidMonitoring: false,
            invalidServices: false,
        }
    },
  ...mapGetters('auth', ['permissions']),
    validations() {
        return this.validationRules;
    },
    methods: {
        async setPayload(tenant) {
            this.payload.title = tenant.title;
            this.payload.note = tenant.note;
            this.payload.search_rate = tenant.search_rate;
            this.payload.monitoring_rates = tenant.monitoring_rates;
            this.setDefaultMonitoringRates(tenant);
            this.payload.users_limit = tenant.users_limit;
            await this.$refs.serviceTable.setRates(tenant);
            await this.$refs.monitoringServiceTable.setRates(tenant);
            await this.$refs.serviceTable.getServices(tenant.owner.id)
            await this.$refs.monitoringServiceTable.getServices(tenant.owner.id)
            await this.$refs.serviceTable.setLoading(false);
            await this.$refs.serviceTable.setValidationRules();
        },
        setDefaultMonitoringRates(tenant){
          if (typeof tenant.monitoring_rates !== 'undefined' && tenant.monitoring_rates.length > 0) {
            this.online_status_price = tenant.monitoring_rates.find(item=> item.type === 'online_status').price
            this.location_price = tenant.monitoring_rates.find(item=> item.type === 'location').price
          }
        },
        setSearchRates(rates) {
            this.payload.services_search_rates = rates;
        },
        setMonitoringRates(rates){
          this.payload.services_monitoring_rates = rates;
        },
        update() {
            if(!this.payload.title) {
                this.payload.title = undefined;
            }
            if(!this.payload.note) {
                this.payload.note = undefined;
            }
            this.sending = true
            this.$store.dispatch('tenants/update', { id: this.$route.params.id, data: this.payload })
                .then(() => {
                    this.sending = false
                    this.$router.push({ name: 'Users' });
                })
        },
        setInsideInvalid(invalid) {
            this.invalid = invalid;
        },
        setServicesState(state){
          this.invalidServices = state;
        },
        setMonitoringState(state){
          this.invalidMonitoring = state;
        }
    },
    watch: {
        'payload.users_limit': {
            handler(val) {
                if(val === '') {
                    this.payload.users_limit = null
                }
            }
        },
        online_status_price(){
          if (typeof this.payload.monitoring_rates !== 'undefined'){
            if (this.payload.monitoring_rates.length > 0){
              let temp = JSON.parse(JSON.stringify(this.payload.monitoring_rates));
              temp = temp.filter(item => item.type !== 'online_status')
              temp.push({
                "type": "online_status",
                "price": this.online_status_price,
              })
              this.payload.monitoring_rates = temp;
            }
          }

        },
        location_price(){
          if (typeof this.payload.monitoring_rates !== 'undefined'){
            if (this.payload.monitoring_rates.length > 0){
              let temp = JSON.parse(JSON.stringify(this.payload.monitoring_rates));
              temp = temp.filter(item => item.type !== 'location')
              temp.push({
                "type": "location",
                "price": this.location_price,
              })
              this.payload.monitoring_rates = temp;
            }
          }
        },
    },
    mounted() {

    }
}
</script>
<style>
.table .input-inside {
    height: 22px;
    width: 50px;
    max-width: 100%;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 2px;
}
.table .input-inside:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25)
}
body .form-control.is-invalid {
    background-image: none;
    background-size: 0;
    padding-right: 0.75rem;
}
.table .input-inside.is-invalid {
    border: 1px solid #dc3545;
}
.table .input-inside.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25);
}
.table-costs-edit tbody td, .table-costs-edit tfoot td {
    padding-top: 5px;
    padding-bottom: 5px;
}
.table-costs-edit thead th {
    padding-top: 10px;
    padding-bottom: 10px;
}
.form-costs-edit .form-group {
    margin-bottom: 10px;
}

.tableFixHead table {
    width: 100%;
}

/*.tableFixHead thead, .tableFixHead tbody, .tableFixHead tr, .tableFixHead td, .tableFixHead th { */
/*    display: block; */
/*}*/
/*.tableFixHead tbody td {*/
/*    height: 34px;*/
/*    padding-top: 3px;*/
/*    border-right-width: 0;*/
/*}*/
/*.tableFixHead tbody th {*/
/*    padding-top: 2px;*/
/*    padding-bottom: 2px;*/
/*}*/

/*.tableFixHead tbody tr {*/
/*    border-top: 0;*/
/*}*/

/*.tableFixHead tbody tr td {*/
/*    line-height: 25px;*/
/*}*/

/*.tableFixHead tr:after {*/
/*    content: ' ';*/
/*    display: block;*/
/*    visibility: hidden;*/
/*    clear: both;*/
/*}*/

/*.tableFixHead thead th {*/
/*    height: 30px;*/
/*    padding-top: 2px;*/
/*    !*text-align: left;*!*/
/*}*/

/*.tableFixHead tbody {*/
    /*max-height: calc(100vh - 250px);*/
    /*overflow-y: auto;*/
/*}*/
/*.tableFixHead tbody td, .tableFixHead thead th {*/
/*    width: 25%;*/
/*    float: left;*/
/*}*/

/*.tableFixHead tbody td:first-child, .tableFixHead thead th:first-child {*/
/*    width: 50%;*/
/*}*/

/*@media screen and (min-width: 992px) {*/
/*    .tableFixHead thead th {*/
/*        width: calc(25% / 2);*/
/*    }*/
/*    .tableFixHead thead th:nth-child(1), .tableFixHead thead th:nth-child(4) {*/
/*        width: 25%;*/
/*    }*/

/*}*/

.tableFixHead img {
    width: 15px;
}
/*.tableFixHead tfoot tr td {*/
/*    float: left;*/
/*    width: 50%;*/
/*    height: 34px;*/
/*}*/
/*.tableFixHead tfoot tr td:first-child{*/
/*    width: 50%;*/
/*}*/

/*@media screen and (min-width: 992px) {*/
/*    .tableFixHead tfoot tr td {*/
/*        width: 75%;*/
/*    }*/
/*    .tableFixHead tfoot tr td:first-child{*/
/*        width: 25%;*/
/*    }*/
/*    .header-last {*/
/*        border-right: 1px solid #ccc*/
/*    }*/
/*}*/

</style>