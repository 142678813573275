<template>
    <div class="card">
            <TenantForm ref="tenantForm" v-show="!loading"></TenantForm>
            <div class="text-center" v-show="loading">
                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TenantForm from './form.vue'
export default {
    name: "TenantEdit",
    components: {
        TenantForm
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters('tenants', ['tenant'])
    },
    methods: {
        getTenant() {
            this.loading = true;
            this.$store.dispatch('tenants/getTenant', this.$route.params.id).finally(() => {
                this.loading = false
                this.$refs.tenantForm.setPayload(this.tenant);
            });
        }
    },
    mounted() {
        this.getTenant();
    }
}
</script>