<template>
  <div v-if="isLoading">
    <b-skeleton width="85%"></b-skeleton>
    <b-skeleton width="55%"></b-skeleton>
    <b-skeleton width="70%"></b-skeleton>
    <b-skeleton width="85%"></b-skeleton>
    <b-skeleton width="55%"></b-skeleton>
    <b-skeleton width="70%"></b-skeleton>
  </div>
  <div class="priceMonitoringTables" v-else>
    <div class="table">
      <div class="title">{{ $t('monitoring.monitoring_social_activity') }}</div>
      <b-table
          sticky-header
          head-variant="light"
          :items="onlineServices"
          :fields="fields"
          :empty-text="$t('billing.empty_text')"
          responsive
          show-empty
          small
          :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(name)="row">
          <div style="display:flex; align-items: center">
            <img :src="row.item.icon_url" :alt="row.item.name" style="width: 18px;height: 18px; margin-right: 10px">
            <div> {{ row.value }} </div>
          </div>
        </template>
        <template #cell(price)="row">
          <div style="width: 100%; display:flex; justify-content: flex-end">
            <input :id="'monitoringOnlineInput'+row.item.id" class="input-inside" type="number"
                   min="0"
                   :value="setOnlineServicePrice(row.item.name)"
                   @input="changeOnlineServicePrice($event.target.valueAsNumber, row.item, 'monitoringOnlineInput'+row.item.id)"
            >
          </div>

        </template>

      </b-table>




    </div>
    <div class="table">
      <div class="title">{{ $t('monitoring.monitoring_location') }}</div>
      <b-table
          sticky-header
          head-variant="light"
          :items="locationServices"
          :fields="fields"
          :empty-text="$t('billing.empty_text')"
          responsive
          show-empty
          small
          :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(name)="row">
          <div style="display:flex; align-items: center">
            <img :src="row.item.icon_url" :alt="row.item.name" style="width: 18px;height: 18px; margin-right: 10px">
            <div> {{ row.value }} </div>
          </div>
        </template>
        <template #cell(price)="row">
          <div style="width: 100%; display:flex; justify-content: flex-end">
            <input :id="'monitoringLocationInput'+row.item.id" class="input-inside" type="number"
                   min="0"
                   :value="setOnlineServicePrice(row.item.name)"
                   @input="changeOnlineServicePrice($event.target.valueAsNumber, row.item, 'monitoringLocationInput'+row.item.id)"
            >
          </div>

        </template>

      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import {validationMixin} from "vuelidate";
import groupObj from "@/helpers/groupObj";
export default {
  name: "MonitoringServiceTable",
  mixins: [validationMixin],
  setup() {
    return {
      und: _
    }
  },
  data() {
    return {
      payload: {
        services_monitoring_rates: []
      },
      onlineServices: [],
      locationServices: [],
      isLoading: true,
      validationRules: {
        payload: {
          services_monitoring_rates: {

          },
        }
      },
      payloadOut: [],
      online_status_price: 0,
      location_price: 0,
      notValidVals: []
    }
  },
  validations() {
    return this.validationRules
  },
  computed: {
    ...mapGetters('monitoring', ['sources']),
    fields(){
      return [
        {
          key: 'name',
          label: this.$t('monitoringLocation.name'),
          sortable: true
        },
        {
          key: 'price',
          label: this.$t('tenants.cost'),
          sortable: true
        },
      ]
    },
  },
  watch: {
    notValidVals() {
      if (this.notValidVals.length > 0) {
        // this.$emit('invalid',true)
        this.$emit('invalidMonitoring',true)
      } else {
        // this.$emit('invalid', false)
        this.$emit('invalidMonitoring', false)
      }
    }
  },
  methods: {

    setOnlineServicePrice(service){
      return this.payload.services_monitoring_rates.find(item => item.source === service).price;
    },
    changeOnlineServicePrice(price, service, elId){
      let el = document.getElementById(elId)
      if (Number.isNaN(price)){
        el.classList.add('is-invalid')
        this.notValidVals.push(elId)
      } else if (this.notValidVals.includes(elId)){
        el.classList.remove('is-invalid')
        this.notValidVals = this.notValidVals.filter(item => item !== elId)
      }
      this.payload.services_monitoring_rates.find(item => item.source === service.name).price = price
      this.$emit('services', this.payload.services_monitoring_rates)
    },

    getServices(user_id) {
      return new Promise(resolve => {
        this.$store.dispatch('monitoring/getSources', {
          order_by: "released_at",
          order_type: "desc",
          filters: {
            user_id
          }
        }).finally(async () => {
          this.isLoading = false;
          let services = []
          this.sources.data.forEach(service=> {
            let getService = this.payloadOut.find(item=> item.source === service.name)
            if (typeof getService !== 'undefined'){
              services.push({
                source: service.name,
                price: getService.price
              })
            } else {
              services.push({
                source: service.name,
                price: service.monitoring_types.includes('online_status') ? this.online_status_price : this.location_price
              })
            }
          })
          this.onlineServices = this.sources.data.filter(item=> item.monitoring_types.includes('online_status'))
          this.locationServices = this.sources.data.filter(item=> item.monitoring_types.includes('location'))
          this.payload.services_monitoring_rates = services
          this.$emit('services', this.payload.services_monitoring_rates)

          // const services = this.sources.data.map((service, ind) => {
          //   const addObj = {ind};
          //   const getService = _.find(this.payloadOut, (ser) => ser.source === service.name);
          //   if(!_.isUndefined(getService)) {
          //     addObj['offline_result_price'] = getService.offline_result_price;
          //     addObj['online_result_price'] = getService.online_result_price;
          //   }
          //   const retObj = Object.assign(service, addObj);
          //   this.payload.services_search_rates[addObj.ind] = {
          //     source: service.name,
          //     offline_result_price: _.isUndefined(addObj['offline_result_price']) ?  this.offline_result_price: addObj['offline_result_price'],
          //     online_result_price: _.isUndefined(addObj['online_result_price']) ? this.online_result_price: addObj['online_result_price'],
          //     params: getService.params,
          //     options: getService.options
          //   }
          //   return retObj;
          // });
          // let single = services.filter(item=> item.search_params.length === 1)
          // let multiple = services.filter(item=> item.search_params.length > 1)
          // this.sorted = services.group(groupObj);
          // this.sort();
          //
          // this.sortedSingle = single;
          // this.sortedMultiple = multiple;
          //
          // this.$emit('services', this.payload.services_search_rates)
          resolve();
        })
      })
    },
    setRates(tenant) {
      // console.log(tenant)
      if (typeof tenant.services_monitoring_rates !== 'undefined'){
        this.payloadOut = tenant.services_monitoring_rates;
      }
      if (typeof tenant.monitoring_rates !== 'undefined'){
        this.online_status_price = tenant.monitoring_rates.find(item=> item.type === 'online_status').price;
        this.location_price = tenant.monitoring_rates.find(item=> item.type === 'location').price;
      }
    },
  },
}
</script>

<style lang="scss">
.priceMonitoringTables{
  .table {
    .title {
      font-weight: 600;
    }
  }
}
</style>